#header {
    float: left;
    width: 220px;
    height: 100%;
}

#header .pro-sidebar {
    height: 100vh;
}

#header .closemenu {
    /* color: #000; */
    /* position: absolute; */
    /* right: 0; */
    z-index: 9999;
    /* line-height: 20px; */
    /* border-radius: 50%; */
    font-weight: bold;
    font-size: 22px;
    /* top: 55px; */
    cursor: pointer;
    width: auto;
    /* display: flex; */
}

#header .closeicon {
    display: flex;
}

#header .pro-sidebar {
    width: 100%;
    min-width: 100%;
}

#header .pro-sidebar.collapsed {
    width: 80px;
    min-width: 80px;
}

#header .pro-sidebar-inner {
    /* background-color: #333333; */
    box-shadow: 0.5px 0.866px 2px 0px rgba(0, 0, 0, 0.15);
}

#header .pro-sidebar-inner .pro-sidebar-layout {
    overflow-y: hidden;
}

#header .pro-sidebar-inner .pro-sidebar-layout .logotext {
    font-size: 20px;
    padding: 0 20px;
    /* display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row; */
    /* color: white; */
    font-weight: bold;
}

#header .pro-sidebar-inner .pro-sidebar-layout ul {
    padding: 0 5px;
}

#header .pro-sidebar-inner .pro-sidebar-layout ul .pro-inner-item {
    /* color: white;tom gelo */
    /* margin: 10px 0px; */
    font-weight: bold;
    user-select: none;
}

#header .pro-sidebar-inner .pro-sidebar-layout ul .pro-inner-item .pro-icon-wrapper {
    /* background-color: black; */
    /* color: white; */
    /* border-radius: 3px; */
}

#header .pro-icon {
    animation: none !important;
}

#header .pro-sidebar-footer li:last-child .pro-icon:last-child {
    background-color: #D00000;
    /* border-radius: 3px; */
}

#header .pro-sidebar-inner .pro-sidebar-layout .active {
    background-image: linear-gradient(0deg, #000000 0%, #45bd9f 100%);
}

#header .logo {
    padding: 20px;
}

@media only screen and (max-width: 720px) {
    html {
        overflow: hidden;
    }
}