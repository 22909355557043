.global-custom-row:hover,
.global-custom-row:hover .InovuaReactDataGrid__cell {
  background: #4e3207 !important;
}

.global-custom-row-green {
  background: #153115 !important;
}
.global-custom-row-green:hover,
.global-custom-row-green:hover .InovuaReactDataGrid__cell {
  background: #2e6538 !important;
}

.global-custom-row-dark-magenta {
  background: #382a38 !important;
}
.global-custom-row-dark-magenta:hover,
.global-custom-row-dark-magenta:hover .InovuaReactDataGrid__cell {
  background: #382a38 !important;
}

.global-custom-row.InovuaReactDataGrid__row--selected,
.global-custom-row.InovuaReactDataGrid__row--selected
  .InovuaReactDataGrid__cell {
  background: #0a265a !important;
  color: white;
}

.global-custom-row.InovuaReactDataGrid__row--selected:hover,
.global-custom-row.InovuaReactDataGrid__row--selected:hover
  .InovuaReactDataGrid__cell {
  background: #13326d !important;
  color: white;
}